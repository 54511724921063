@import "./base.scss";

.error {
    color: $error-color;
    font-size: 14px;
    margin-top: 0.3rem;
    font-weight: 500;

    &:before {
        content: url("../assets/arrow.svg");
        padding: 0px 6px;
    }
}

.page-title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    color: #000000;
    text-transform: capitalize;
    padding-bottom: 2rem;
}
