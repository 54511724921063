$primary-color: #2b5336;
$secondary-color: #2b5336;

$error-color: #eb2931;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");



.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option:hover {
    border-left: none !important;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option-selected {
    border-left: 2px solid rgba(43, 83, 54) !important;
    background-color: #eb2931;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-wrap
    .side-navigation-panel-select-option-selected {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
    border-color: rgb(18, 102, 18) !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option-selected {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: #fffbce !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
    color: rgba(31, 41, 55, var(--tw-text-opacity));
    width: 227px !important;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option {
    padding: 0.5rem 2.5rem !important;
}

.side-navigation-panel
    .side-navigation-panel-select
    .side-navigation-panel-select-inner
    .side-navigation-panel-select-inner-wrap
    .side-navigation-panel-select-inner-option:hover {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
    border-color: rgb(18, 102, 18) !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
}
